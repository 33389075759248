.company-details {
  display: flex;
  flex-direction: column;
  padding-top: 2.4rem;

  @include media($tablet-min) {
    flex-direction: row;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 3.2rem;

      @include media($mobile-min) {
        margin-bottom: 4rem;
      }

      @include media($tablet-min) {
        margin-bottom: 0;
        margin-right: 13.6rem;
      }
    }
  }

  &__item-title {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.24rem;
    margin-bottom: 0.8rem;

    @include media($mobile-min) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 1.6rem;
    }

    div {
      width: 1.6rem;
      height: 2.4rem;
      margin-bottom: 1.6rem;

      @include media($mobile-min) {
        margin-right: 1.2rem;
        margin-bottom: 0;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: $blue-dark;
      }
    }
  }

  &__item-text {
    font-size: 1.4rem;
    line-height: 1.678rem;
    display: flex;
    flex-direction: column;

    @include media($mobile-min) {
      font-size: 1.6rem;
      line-height: 2.24rem;
    }
  }
}

.company-info__row {
  display: flex;
  padding-top: 2.4rem;
  justify-content: space-between;
}

.documents {
  padding-top: 2.4rem;
}