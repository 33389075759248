.link-text {
  color: $gold;
  font-size: 1.6rem;
  line-height: 1.92rem;
  display: inline-block;
  padding: 3px;
  border-bottom: 2px dashed currentColor;

  &:hover {
    color: $dark-gold;
  }

  @include media($mobile-min) {
    font-size: 1.9rem;
    line-height: 2.328rem;
  }
}

.read-more-link {
  color: $gold;

  &:hover {
    color: $dark-gold;
  }
}