.features {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    flex: 0 0 100%;

    @include media($tablet-min) {
      flex-basis: 33.333333%;
    }
  }
}

.feature {
  background-color: $grey-dark;
  color: #fff;
  padding: 2.4rem 1.6rem 3.2rem;
  cursor: default;

  @include media($mobile-min) {
    padding: 5rem 3.2rem 5.6rem;
  }

  @include media($tablet-min) {
    padding: 7.4rem 10rem;
    display: flex;
  }

  &:nth-child(2n) {
    background-color: $grey-darkest;
  }

  &__icon-box {
    width: 3.2rem;
    height: 3.2rem;
    margin-bottom: 1.6rem;

    @include media($mobile-min) {
      margin-bottom: 0;
      margin-right: 3.8rem;
      flex-shrink: 0;
    }
  }

  &__icon {
    display: block;
    width: 100%;
    height: 100%;
    fill: #fff;
  }

  &__title {
    font-size: 1.6rem;
    line-height: 1.92rem;
    font-weight: 500;
    margin-bottom: .5rem;
    font-family: $secondary-font;

    @include media($mobile-min) {
      font-size: 1.9rem;
      font-weight: 600;
      line-height: 3.8rem;
    }
  }

  &__desc {
    font-size: 1.4rem;
    line-height: 1.68rem;
    margin: 0;

    @include media($mobile-min) {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }
}