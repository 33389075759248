.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 2.7rem 3.8rem;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all .3s;

  &--gold {
    color: #fff;
    background-color: $gold;

    &:hover,
    &:focus {
      background-color: $dark-gold;
    }
  }
}

.btn-scroll-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: $btn-scroll-to-top-bg;
  color: $gold;
  font-size: 1.6rem;
  padding: 2.2rem;

  @include media($tablet-min) {
    display: none;
  }
}

.btn-burger {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.4rem;

  @include media($tablet-min) {
    display: none;
  }

  span {
    display: block;
    width: 3rem;
    height: 2px;
    background-color: $btn-burger-color;
    position: relative;
    transition: all .3s ease;

    &::before,
    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: $btn-burger-color;
      transition: all .3s ease;
    }

    &::before {
      transform: translateY(-7px);
    }

    &::after {
      transform: translateY(7px);
    }
  }

  &.open {
    span {
      background-color: transparent;

      &::before,
      &::after {
        background-color: $gold;
      }

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }
}