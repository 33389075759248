.admin-contacts {
  padding: 20px;

  h2 {
    margin-bottom: 30px;
  }

  .contacts-display {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h3 {
      color: #333;
      margin-bottom: 20px;
    }

    .contact-info,
    .director-info {
      margin-bottom: 30px;

      p {
        margin: 10px 0;
        
        strong {
          color: #666;
          margin-right: 10px;
        }
      }
    }
  }

  .edit-button {
    background: #007bff;
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;

    &:hover {
      background: #0050a6;
    }
  }
}

// Стили для модального окна
.contacts-form {
  padding: 20px;
  min-width: 400px;

  h3 {
    margin-bottom: 20px;
    color: #333;
  }

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      color: #666;
    }

    input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;

      &:focus {
        border-color: #0050a6;
        outline: none;
      }
    }
  }

  .form-actions {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    button {
      padding: 8px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background 0.3s;

      &.save-button {
        background: #007bff;
        color: white;

        &:hover {
          background: #0050a6;
        }
      }

      &.cancel-button {
        background: #f5f5f5;
        color: #666;

        &:hover {
          background: #eee;
        }
      }
    }
  }
}

// Стили для react-responsive-modal
.react-responsive-modal-modal {
  padding: 0;
  max-width: 90%;
  max-height: 90vh;
  overflow: auto;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
}