.section {
  padding: 4rem 0;
  position: relative;

  @include media($mobile-min) {
    padding: 11rem 0;
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 2.1rem;

    @include media($mobile-min) {
      margin-bottom: 5rem;
    }
  }

  &--promotion {
    @include media($mobile-max, '<') {
      padding-bottom: 9.4rem;
    }

    .heading-secondary {
      @include media($mobile-min) {
        margin-right: 5rem;
      }
    }

    .swiper-pagination {
      @include media($mobile-max, '<') {
        position: absolute;
        bottom: 5.7rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    #promo-slider-pagination {
      width: auto;
    }
  }

  &--price {
    border-bottom: 1px solid $blue-light;

    .section__head {
      @include media($tablet-min) {
        justify-content: space-between;
      }

      @include media($tablet-max, '<') {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .heading-secondary {
      margin-bottom: 2.4rem;

      @include media($mobile-min) {
        margin-bottom: 1.6rem;
      }

      @include media($tablet-min) {
        margin-bottom: 0;
      }
    }
  }

  &--feedback {
    position: relative;

    @include media($mobile-max, '<') {
      padding-bottom: 5rem;
    }

    .swiper-pagination {
      @include media($mobile-max, '<') {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    #feedback-slider-pagination {
      width: auto;
    }

    .section__head {
      @include media($mobile-max, '<') {
        flex-direction: column;
      }
    }

    .heading-secondary {
      text-align: center;

      @include media($mobile-min) {
        margin-right: 6.2rem;
      }
    }
  }
}