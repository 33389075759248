.swiper-pagination {
  position: static;
  display: flex;
  align-items: center;
  min-height: 2rem;

  .swiper-pagination-bullet:not(:last-child) {
    margin-right: 2rem;
  }

  &#doctors-slider-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-right: 3.5rem;
  }

  .swiper-pagination-bullet {
    position: relative;
    opacity: 1;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    transition: all .3s ease;
    cursor: pointer;

    &::after {
      display: block;
      content: '';
      width: 9px;
      height: 9px;
      border-radius: 50%;
      border: 2px solid $slider-pagination-bullet-color;
      @include absCenter();
      transition: all .3s ease;
    }

    &:hover {
      &::after {
        border-color: $gold;
      }
    }
  }

  .swiper-pagination-bullet-active {
    &::after {
      width: 12px;
      height: 12px;
      border-color: $slider-pagination-bullet-active-color;
    }

    &:before {
      display: block;
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $slider-pagination-bullet-active-color;
      @include absCenter();
    }
  }
}