@font-face {
  font-family: 'Gilroy';
  src: local('/assets/font/Gilroy-Regular'), local('Gilroy-Regular'),
    url('/assets/font/Gilroy-Regular.woff2') format('woff2'),
    url('/assets/font/Gilroy-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('/assets/font/Gilroy-Medium'), local('Gilroy-Medium'),
    url('/assets/font/Gilroy-Medium.woff2') format('woff2'),
    url('/assets/font/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('/assets/font/Gilroy-Semibold'), local('Gilroy-Semibold'),
    url('/assets/font/Gilroy-Semibold.woff2') format('woff2'),
    url('/assets/font/Gilroy-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}