.price-list {
  color: $text_color-dark;

  @include media($tablet-min) {
    padding-bottom: 8rem;
  }

  &__item {
    padding: 1.6rem 0;

    @include media($tablet-min) {
      display: flex;
      align-items: center;
      padding: 1.6rem 4.4rem;
      transition: all 0.3s ease;
    }

    &:hover {
      @include media($tablet-min) {
        cursor: default;
        background-color: rgba(221, 231, 240, 0.6);
      }
    }

    &:not(:last-child) {
      @include media($tablet-max, "<") {
        border-bottom: 1px solid #000;
      }
    }
  }

  &__title {
    font-size: 1.4rem;
    line-height: 1.68rem;
    margin-bottom: 0.8rem;
    font-weight: 400;      

    @include media($mobile-min) {
      font-size: 1.8rem;
      line-height: 2.5rem;
      margin-bottom: 1.6rem;
    }

    @include media($tablet-min) {
      margin-bottom: 0;
      font-size: 1.8rem;
      line-height: 2.5rem;
      flex: 0 0 72%;
      padding-right: 2rem;
    }
  }

  &__details {
    display: flex;
    align-items: center;
    justify-content: space-between;    

    @include media($tablet-min) {
      margin-left: auto;
      flex: 0 0 28%;
    }
  }

  &__price {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.92rem;

    @include media($mobile-min) {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 2.16rem;
    }

    @include media($tablet-min) {
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: $text-color-dark;
      font-weight: 400;
      padding-right: 2rem;
      flex: 0 0 40%;
    }
  }

  &__code {
    font-size: 1.2rem;
    line-height: 1.44rem;
    color: rgba($text-color-dark, 0.2);

    @include media($tablet-min) {
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: $text-color-dark;
      flex: 0 0 60%;
    }
  }
}