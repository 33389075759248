.heading-secondary {
  font-size: 2.1rem;
  line-height: 2.64rem;
  font-weight: 500;
  text-align: center;
  font-family: $secondary-font;
  cursor: default;

  @include media($mobile-min) {
    font-size: 3.4rem;
    line-height: 4rem;
    text-align: left;
  }
}

.text {
  font-size: 1.4rem;
  line-height: 1.68rem;

  @include media($mobile-min) {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}