//Font properties
$base-font: 'Roboto', sans-serif;
$secondary-font: 'Gilroy', sans-serif;
$semi-bold: 600;
$bold: 700;

//Colors
$text-color: #333333;
$text-color-dark: #222222;
$text-color-darkest: #111111;
$gold: #C2A073;
$dark-gold: #AB8654;
$blue: #C5D4E2;
$blue-light: #DDE7F0;
$blue-dark: #8CA5BC;
$grey-dark: #272727;
$grey-darkest: #1d1d1d;
$slider-pagination-bullet-color: #A9BCCE;
$slider-pagination-bullet-active-color: #C4A985;
$accordion-summary-bg: #272727;
$accordion-summary-bg-active: #1d1d1d;
$footer-bg: #272727;
$btn-scroll-to-top-bg: #1D1D1D;
$menu-bg: #272727;
$btn-burger-color: #222;

// Breakpoints
$mobile-min: 768;
$mobile-max: 767;
$tablet-min: 1366;
$tablet-max: 1365;