.footer {

  &__main {
    background-color: $footer-bg;
    padding-top: 4rem;
    padding-bottom: 6.2rem;

    @include media($mobile-min) {
      padding-top: 6.4rem;
      padding-bottom: 7.3rem;
    }

    @include media($tablet-min) {
      padding-top: 11rem;
      padding-bottom: 10.8rem;
    }
  }

  &__wrapper {
    @include media($mobile-min) {
      display: flex;
    }
  }

  &__logo-box {
    display: block;
    height: 3.4rem;
    margin-bottom: 1.6rem;

    @include media($mobile-min) {
      height: 4.1rem;
      margin-bottom: 0;
      margin-right: 4.1rem;
      flex-shrink: 0;
    }
  }

  &__logo-img {
    display: block;
    height: 100%;
    width: auto;
  }

  &__content {
    color: #fff;

    @include media($mobile-min) {
      flex: 1 1 auto;
    }

    @include media($tablet-min) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    font-family: $secondary-font;
    font-size: 1.6rem;
    line-height: 1.92rem;
    font-weight: 500;
    margin-bottom: 1.6rem;

    @include media($mobile-min) {
      font-size: 1.9rem;
      font-weight: 600;
    }
  }

  &__text {
    margin-bottom: 3.2rem;

    p {
      color: #fff;
    }

    @include media($mobile-min) {
      padding-top: 1.2rem;
      margin-bottom: 4rem;
    }

    @include media($tablet-min) {
      max-width: 59rem;
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }

  &__contacts {
    @include media($tablet-min) {
      padding-top: 1.2rem;
    }
  }

  &__map {
    min-height: 40rem;
  }

  &__divider {
    background-color: #1D1D1D;
    height: 6.4rem;

    @include media($tablet-max, '<') {
      display: none;
    }
  }
}