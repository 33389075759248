.documents {
  gap: 2.4rem;
  &__title {
    font-size: 1.4rem;
    line-height: 1.68rem;
    font-weight: 400;
    margin-bottom: 1.6rem;

    @include media($mobile-min) {
      font-size: 1.6rem;
      line-height: 2.24rem;
      margin-bottom: 4rem;
    }

    @include media($tablet-min) {
      margin-bottom: 1.6rem;
    }
  }

  &__grid {
    padding: 5rem 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
    margin-left: -.6rem;
    margin-right: -.6rem;
    margin-top: -.6rem;

    @include media($mobile-min) {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      margin-top: -1.5rem;
    }

    @include media($tablet-min) {
      margin-left: -.8rem;
      margin-right: -.8rem;
      margin-top: -.8rem;
    }
  }

  &__item {
    flex: 0 0 33.33333%;
    padding: .6rem;
    overflow: hidden;

    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  
    @include media($mobile-min) {
      padding: 1.5rem;
    }

    @include media($tablet-min) {
      flex: 0 0 16.666666%;
      padding: .8rem;
      height: 17rem;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;

    &__wrapper {
      height: 100%;
      border: none;
    }
  }
}