.section-hero {
  display: flex;
  flex-direction: column;
  height: var(--hero-mobile-height);   
  background: url("/assets/img/hero-wave-1.png") no-repeat 80% bottom, url("/assets/img/hero-wave-2.png") no-repeat right top, $blue;

  #hero-slider {
    z-index: 2;
  }
  
  @include media($mobile-min) {
    height: auto;
  }

  @include media($tablet-min) {
    min-height: 68.2rem;
  }

  &__head {
    display: flex;
    align-items: center;
  }

  &__wrapper {
    flex: 1 1 auto;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 14rem;

    @include media($mobile-min) {
      padding-bottom: 0;
    }

    @include media($tablet-min) {
      flex-direction: row;
      margin-top: auto;
    }
  }

  &__slider {
    margin-top: 4rem;

    @include media($mobile-min) {
      margin-bottom: 2.4rem;
    }      

    @include media($tablet-min){
      width: 100%;
      max-width: 74.2rem;
      margin-bottom: 0;
      margin-top: 8rem;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    @include media($mobile-min) {
      flex-direction: row;
      position: static;
      width: auto;
    }
  }

  &__img-box {
    @include media($mobile-max, '<') {
      position: absolute;
      bottom: 0;
      width: calc(100% - 32px);
      max-width: 33rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }

    @include media($mobile-min) {
      max-width: 50rem;
      align-self: center;
    }

    @include media($tablet-min) {
      max-width: 53.6rem;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    @include media(1600) {
      right: -13rem;
    }      
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__btn {
    width: 100%;
    max-width: 32rem;
    font-size: 1.8rem;
    padding: 2.7rem;
    margin-bottom: 1.6rem;

    @include media($mobile-max, '<') {
      width: calc(100% - 32px);
    }

    @include media($mobile-min) {
      margin-right: 4rem;
      margin-bottom: 0;
    }

    &--call {
      @include media($tablet-min) {
        display: none;
      }
    }

    &--appointment {
      @include media($tablet-max, '<') {
        display: none;
      }
    }
  }

  &__pagination {
    @include media($mobile-max, '<') {
      height: 4.4rem;
      background-color: #272727;
      width: 100%;
      justify-content: center;
    }      
  }

  &__title {
    font-size: 2.8rem;
    line-height: 3rem;
    font-weight: 500;
    color: $text-color-dark;
    margin-bottom: .8rem;
    font-family: $secondary-font;
    cursor: default;

    @include media($mobile-min) {
      font-size: 5rem;
      line-height: 6rem;
      margin-bottom: 1.7rem;
    }

    @include media($tablet-min) {
      margin-bottom: 3.2rem;
    }
  }

  &__desc {
    font-size: 1.5rem;
    line-height: 2.1rem;
    margin-bottom: .5rem;
    cursor: default;

    @include media($mobile-min) {
      font-size: 1.9rem;
      line-height: 2.8rem;
      margin-bottom: 5.2rem;
    }

    @include media($tablet-min) {
      margin-bottom: 5.6rem;
    }
  }

  &--height-auto {
    height: auto;
    min-height: auto;
  }

  &__contacts {
    padding-top: 7.5rem;

    @include media($mobile-max, '<') {
      padding-top: 4rem;
    }
  }

  &--doctors {
    padding-bottom: 8rem;
    background: url("/assets/img/hero-wave-1.png") no-repeat -5% bottom, url("/assets/img/hero-wave-2.png") no-repeat right top, $blue;

    .section-hero__title {
      margin-right: 3.2rem;
      margin-bottom: 0;
      margin-top: 0;
    }

    .breadcrumbs {
      margin-top: 7rem;

      @include media($mobile-min) {
        margin-top: 14rem;
      }

      @include media($tablet-min) {
        margin-top: 5rem;
      }
    }

    .doctor-info {
      margin-top: 2.3rem;

      @include media($mobile-min) {
        margin-top: 5.6rem;
      }

      @include media($tablet-min) {
        margin-top: 5.4rem;
      }
    }

    #doctors-slider-pagination{
      display: none;
      
      @include media(650) {
        display: flex;
        overflow: hidden;
      }
    }

    .section-hero__head {
      margin: 4rem 0 ;

      @include media($mobile-min)  {
        margin-top: 11rem;
      }

      @include media($tablet-min) {
        margin-top: 12rem;
      }
    }

    @include media($mobile-min) {
      padding-bottom: 11rem;
    }

    @include media($tablet-min) {
      padding-bottom: 10.4rem;
    }
  }

  &--documents {
    padding-bottom: 6.4rem;
    background: url("/assets/img/hero-wave-3.png") no-repeat left bottom, url("/assets/img/hero-wave-2.png") no-repeat right top, $blue;

    @include media($mobile-min) {
      padding-bottom: 9rem;
    }

    @include media($tablet-min) {
      padding-bottom: 10.9rem;
    }

    .section-hero__title {
      margin-top: 3rem;
      margin-bottom: 4rem;

      @include media($mobile-min) {
        margin-top: 8.2rem;
        margin-bottom: 4.4rem;
      }

      @include media($tablet-min) {
        margin-top: 12.5rem;
        margin-bottom: 4rem;
        max-width: 60rem;
      }
    }
  }

  &:has(.doctor-info) + .footer,
  &:has(.error) + .footer {
    margin: 0;
  }
}