.accordion {
  &__item {
    &:not(:last-child) {
      .accordion__summary {
        border-bottom: 1px solid $accordion-summary-bg-active;
      }
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 1.6rem;
    background-color: $accordion-summary-bg;
    border: none;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      background-color: $accordion-summary-bg-active;
    }

    &[aria-expanded="true"] {
      background-color: $accordion-summary-bg-active;

      .accordion__icon {
        transform: rotate(180deg);
      }
    }

    @include media($tablet-min) {
      padding: 2rem 4.4rem;
    }
  }

  &__title {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.92rem;
    font-family: $secondary-font;

    @include media($mobile-min) {
      font-size: 1.8rem;
      line-height: 2.16rem;
      font-weight: 600;
    }
  }

  &__icon {
    display: block;
    margin-left: 1rem;
    width: 1.6rem;
    height: 1.6rem;
    transition: all .3s ease;

    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: $gold;
    }
  }
}