.promo-slide {
  color: $text-color;
  cursor: default;

  &__img {
    height: 15.2rem;
    margin-bottom: .8rem;
    background-repeat: no-repeat;
    background-size: cover;

    @include media($mobile-min) {
      height: 35rem;
      margin-bottom: 2.5rem;
    }
  }

  &__title {
    font-size: 1.4rem;
    line-height: 1.68rem;
    margin-bottom: .4rem;
    font-weight: 400;

    @include media($mobile-min) {
      font-size: 1.8rem;
      line-height: 2.5rem;
      margin-bottom: .6rem;
    }
  }

  &__price {
    font-size: 2.2rem;
    line-height: 3rem;
    font-family: $secondary-font;
    font-weight: 600;

    @include media($mobile-min) {
      font-size: 2.8rem;
      line-height: 3.8rem;
    }
  }
}