.company-info {
  &__list {
    padding-bottom: 5.6rem;
    padding-top: .8rem;

    @include media($mobile-min) {
      padding-bottom: 7.8rem;
    }

    @include media($mobile-min) {
      padding-top: 3.3rem;
    }
  }

  &__item {
    padding: 3.2rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid $blue-light;
    }
  }

  &__item-title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.24rem;
    margin-bottom: .8rem;
  }

  &__item-text {
    font-size: 1.4rem;
    line-height: 1.68rem;

    @include media($mobile-min) {
      font-size: 1.6rem;
      line-height: 2.24rem;
    }

    a {
      text-decoration: underline;
      color: $gold;
    }

    span {
      display: block;
    }
  }

  .documents {
    @include media($mobile-min) {
      padding-top: .8rem;
    }
  }

  .documents__item {
    max-width: 10.1rem;
    height: 12.2rem;

    @include media($mobile-min) {
      height: 14.2rem;
    }
  }

  +.footer {
    margin: 0;
  }
}