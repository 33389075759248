.feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;

  @include media($mobile-min) {
    align-items: flex-start;
  }

  &__head {
    display: flex;
    align-items: center;

    @include media($mobile-min) {
      margin-bottom: 4.8rem;
    }
  }

  &__img {
    display: block;
    width: auto;
    max-height: 1.1rem;
    margin-right: .8rem;

    @include media($mobile-min) {
      max-height: 1.7rem;
    }
  }

  &__credentials {
    font-size: 1.2rem;
    color: rgba($text-color-dark, .2);
    font-family: $secondary-font;
    font-weight: 500;

    @include media($mobile-min) {
      font-size: 1.9rem;
      color: $text-color-dark;
    }
  }

  &__body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    order: -1;
    margin-bottom: 2.3rem;

    @include media($mobile-min) {
      order: initial;
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
      margin-bottom: 0;
    }
  }

  &__avatar-box {
    height: 4.8rem;
    width: 4.8rem;
    overflow: hidden;
    margin-bottom: .9rem;
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 2.4rem;
  }

  &__avatar-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media($mobile-min) {
      align-items: flex-start;
    }
  }

  &__title {
    color: $text-color-dark;
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.92rem;
    margin-bottom: 1.6rem;
    order: 1;
  }

  &__rating {
    display: flex;
    align-items: center;
    order: 3;

    @include media($mobile-min) {
      order: 2;
      margin-bottom: 2.2rem;
    }

    svg {
      display: block;
      width: 1.6rem;
      height: 1.5rem;

      &:not(:last-child) {
        margin-right: .8rem;
      }
    }
  }

  &__text {
    margin-bottom: 1.9rem;
    order: 2;

    @include media($mobile-min) {
      order: 3;
      margin-bottom: 0;
    }
  }
}