.admin-documents {
  padding: 20px;

  // Основные заголовки
  h2 {
    margin-bottom: 30px;
    color: #333;
    font-size: 24px;
  }

  h3 {
    margin: 25px 0 20px;
    color: #444;
    font-size: 20px;
  }

  // Секция с таблицами
  .admin-section {
    margin-bottom: 40px;
  }

  // Общие стили для таблиц
  .admin-table {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
    margin-bottom: 30px;
    
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      
      th, td {
        padding: 15px;
        text-align: left;
        border-bottom: 1px solid #eee;
      }

      th {
        background-color: #f8f9fa;
        font-weight: 600;
        color: #444;
        white-space: nowrap;
      }

      td {
        vertical-align: middle;
      }

      // Убираем нижнюю границу у последней строки
      tr:last-child td {
        border-bottom: none;
      }
    }
  }

  // Стили для документов с лицензиями
  .document-licenses {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .license-info {
    .license-image {
      max-width: 120px;
      height: auto;
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 4px;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0,0,0,0.15);
      }
    }
  }

  // Кнопки
  .btn {
    &--edit {
      padding: 8px 16px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s ease;
      font-size: 14px;
      
      &:hover {
        background-color: #0056b3;
        transform: translateY(-1px);
      }

      &:active {
        transform: translateY(0);
      }
    }
  }
} 

.image-preview {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.image-thumbnail {
  width: 100px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.documents-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    
    &:hover {
      background: #555;
    }
  }

  .document-item {
    position: relative;
    width: 150px;
    height: 150px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    .delete-button {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #ff4444;
      color: white;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      
      &:hover {
        background: #cc0000;
      }
    }
  }

  .upload-button {
    width: 150px;
    height: 150px;
    border: 2px dashed #ccc;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #f9f9f9;
    transition: all 0.3s ease;

    &:hover {
      border-color: #007bff;
      background: #f0f7ff;
    }

    svg {
      width: 32px;
      height: 32px;
      margin-bottom: 8px;
      color: #666;
    }

    span {
      font-size: 14px;
      color: #666;
    }

    input {
      display: none;
    }
  }
}
