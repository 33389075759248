:root {
  --app-height: 100%;
  --hero-mobile-height: 100%;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  font-family: $base-font;
  color: $text-color;
  font-weight: 400;
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

::selection {
  background-color: $grey-darkest;
  color: #fff;
}

* {
  -webkit-tap-highlight-color: transparent;
}