.header {
  position: relative;
  z-index: 6;
  transition: all .3s;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;

    @media only screen and (min-width: calc($mobile-min / 16) + "em") and (max-width: calc($tablet-max / 16) + "em") {
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
    }
  }

  &__logo-box {
    display: block;
    height: 3.4rem;

    @include media($mobile-min) {
      height: 4.1rem;
    }

    @include media($tablet-min) {
      margin-right: 3.6rem;
    }
  }

  &__logo-img {
    display: block;
    height: 100%;
    width: auto;

    &--dark {
      display: none;
    }
  }

  &__contacts {   
    @include media($tablet-max, "<") {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    
    @include media($mobile-max, "<") {
      display: none;
    }
  }

  &--sticky {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(34, 34, 34, 0.03);

    .header__logo-img {
      display: none;
    }

    .header__logo-img--dark {
      display: block;
    }
  } 
}

.header__contacts .contacts-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include media($tablet-max, '<') {
    align-items: center;
  }
   
  .contacts-list__link {
    @include media($tablet-max, '<') {
      font-size: 1.4rem;
    }
  }

  .contacts-list__icon-box {
    @include media($tablet-max, '<') {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: .7rem;
    }
  }
}

.header__contacts .contacts-list__item:not(:last-child) {
  margin-bottom: .8rem;
}

.header .container {
  max-width: 100%;
}

.main-nav-open .header {
  @include media($tablet-max, '<')  {
    border-bottom: 1px solid #1d1d1d;
    background-color: #272727;
  }  
}

.main-nav-open  .header__contacts {
  @include media($tablet-max, '<') {
    display: none;
  }  
}