.vacancies-manager {
  padding: 20px;

  h2 {
    margin-bottom: 30px;
    font-size: 24px;
    color: #333;
  }

  .add-button {
    margin-bottom: 20px;
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      background-color: #45a049;
    }

    &:active {
      transform: translateY(1px);
    }

    &::before {
      content: '+';
      font-size: 20px;
      font-weight: bold;
    }
  }

  form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    div {
      margin-bottom: 15px;
    }

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: 500;
      color: #444;
    }

    input,
    textarea,
    select {
      width: 100%;
      padding: 8px 12px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;

      &:focus {
        outline: none;
        border-color: #0066cc;
        box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
      }
    }

    textarea {
      min-height: 100px;
      resize: vertical;
    }

    button {
      padding: 10px 20px;
      background: #0066cc;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      margin-right: 10px;

      &:hover {
        background: #0052a3;
      }

      &[type="button"] {
        background: #666;

        &:hover {
          background: #555;
        }
      }
    }
  }

  .vacancies-list {
    h3 {
      margin-bottom: 20px;
      font-size: 20px;
      color: #333;
    }

    .vacancy-item {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;

      h4 {
        margin: 0 0 10px;
        font-size: 18px;
        color: #222;
      }

      p {
        margin: 0 0 15px;
        color: #666;
        line-height: 1.5;
      }

      .vacancy-actions {
        display: flex;
        gap: 10px;

        button {
          padding: 8px 16px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.2s;

          &:first-child {
            background: #4CAF50;
            color: white;

            &:hover {
              background: #45a049;
            }
          }

          &:last-child {
            background: #f44336;
            color: white;

            &:hover {
              background: #da190b;
            }
          }
        }
      }

      .vacancy-details {
        margin: 15px 0;

        h5 {
          margin: 10px 0 5px;
          font-size: 16px;
          color: #444;
        }

        p {
          margin: 0;
          color: #666;
        }
      }

      .vacancy-status {
        display: inline-block;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;

        &.active {
          background: #e8f5e9;
          color: #2e7d32;
        }

        &.inactive {
          background: #ffebee;
          color: #c62828;
        }
      }
    }
  }
}

.vacancy-edit-form {
  padding: 20px;
  min-width: 500px;

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  div {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #444;
  }

  input,
  textarea,
  select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;

    &:focus {
      outline: none;
      border-color: #0066cc;
      box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
    }
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }

  .form-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;

      &[type="submit"] {
        background: #4CAF50;
        color: white;

        &:hover {
          background: #45a049;
        }
      }

      &[type="button"] {
        background: #f44336;
        color: white;

        &:hover {
          background: #da190b;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .vacancies-manager {
    padding: 15px;

    .add-button {
      width: 100%;
      justify-content: center;
    }

    form {
      padding: 15px;

      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .vacancy-item {
      .vacancy-actions {
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }

  .vacancy-edit-form {
    min-width: auto;
    width: 100%;
    padding: 15px;
  }
}