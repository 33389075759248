.main-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: calc(var(--app-height) - 60px);
  z-index: 5;
  background-color: $menu-bg;
  left: 0;
  top: calc(100% + 1px);
  padding: 1.6rem 1.6rem 1.6rem;
  color: #fff;
  transition: all .3s ease;

  @include media($mobile-min) {
    height: calc(var(--app-height) - 105px);
  }

  @include media($tablet-min) {
    height: auto;
    position: static;
    padding: 0;
    background-color: transparent;
    width: auto;
    margin-right: auto;
  }

  @include media($tablet-max, '<') {
    opacity: 0;
    visibility: hidden;
    overflow-y: scroll;
    transform: translateY(-10px);
    transition: all 0.3s ease;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    margin-bottom: 2rem;

    @include media($mobile-min) {
      margin-bottom: 14rem;
      margin-top: 10rem;
      flex: 0 0 auto;
    }

    @include media($tablet-min) {
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &__item {
    &:not(:last-child) {
      @include media($tablet-max, '<') {
        margin-bottom: 3rem;
      }

      @include media($tablet-min) {
        margin-right: 3.2rem;
      }
    }
  }

  &__link {
    color: #fff;
    font-size: 1.6rem;
    transition: all .3s ease;

    @include media($tablet-min) {
      color: $text-color-dark ;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &.active {
      color: #C2A073;
    }
  }

  &__contacts {
    margin-bottom: 3.2rem;

    @include media($tablet-min) {
      display: none;
    }

    .contacts-list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__btn {
    width: 100%;

    @include media($mobile-min) {
      width: auto;
    }

    @include media($tablet-min) {
      display: none;
    }
  }

  &--open {
    @include media($tablet-max, '<') {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      height: calc(100vh - 61px);
    }
  }
}