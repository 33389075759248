.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__item {
    &:not(:first-child) {
      margin-left: .8rem;

      &::before {
        content: '/';
        display: inline-block;
        color: $gold;
        margin-right: .8rem;
      }
    }
  }

  &__link {
    color: $text-color-dark;
    font-size: 1.6rem;
    line-height: 1.9rem;
    transition: all .3s;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}