.vacancies {
  padding: 4.1rem 0 8.3rem 0;

  @include media($mobile-min) {
    padding: 6.4rem 0 11rem 0;
  }

  &__heading {
    font-size: 2.6rem;
    line-height: 2.24rem;
    font-weight: 700;
    margin-bottom: 1.6rem;
  }

  &__list {
    padding: 0;
    margin: 0;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  }
}

.vacancy {
  &__title {
    font-size: 1.4rem;
    line-height: 1.68rem;
    font-weight: 700;

    @include media($mobile-min) {
      font-size: 1.6rem;
      line-height: 2.24rem;
    }
  }

  &__desc {
    font-size: 1.4rem;
    line-height: 1.68rem;

    @include media($mobile-min) {
      font-size: 1.6rem;
      line-height: 2.24rem;
    }
  }

  &__link {
    font-size: 1.4rem;
    line-height: 1.68rem;

    @include media($mobile-min) {
      font-size: 1.6rem;
      line-height: 2.24rem;
    }
  }
}