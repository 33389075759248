.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90vw;
  max-width: 1200px;
  height: 90vh;
  max-height: 800px;
  position: relative;
  animation: slideIn 0.3s ease;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  
  &:hover {
    color: #666;
  }
}

.form-group {
  margin-bottom: 15px;
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  height: 60px;
  padding: 10px 20px;
  border-top: 1px solid #eee;
} 

.edit-document-form {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;

  .form-content {
    flex: 1;
    overflow-y: auto;
    padding-right: 20px;
    min-width: 500px;

    textarea {
      width: 100%;
      min-height: 200px;
      padding: 12px;
      resize: vertical;
      font-size: 14px;
      line-height: 1.5;
      border: 1px solid #ddd;
      border-radius: 4px;
      
      &:focus {
        outline: none;
        border-color: #0066cc;
        box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.1);
      }
    }
  }

  .form-images {
    .documents-preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #eee;

    button {
      padding: 10px 20px;
      border-radius: 4px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;

      &.btn-primary {
        background: #0066cc;
        color: white;
        border: none;

        &:hover {
          background: #0052a3;
        }
      }

      &.btn-secondary {
        background: white;
        color: #666;
        border: 1px solid #ddd;

        &:hover {
          background: #f5f5f5;
          border-color: #999;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
} 