.doctor-info {
  background-color: #fff;
  padding: 1.6rem 1.6rem 6.4rem;

  @include media($mobile-min) {
    padding: 3.2rem 3.2rem 6.4rem;
  }

  @include media($tablet-min) {
    padding: 6.4rem 15.5rem 6.4rem 6.4rem;
  }

  &__wrapper {
    &:not(:last-child) {
      margin-bottom: 3.2rem;

      @include media($tablet-min) {
        margin-bottom: 1.6rem;
      }
    }

    @include media($tablet-min) {
      display: flex;
    }
  }

  &__column {
    &--left {
      @include media($tablet-min) {
        flex: 0 0 31.4rem;
        margin-right: 6rem;
      }
    }
  }

  &__img-box {
    width: 100%;
    margin-bottom: 1.6rem;

    @include media($mobile-min) {
      margin-bottom: 6rem;
    }

    @include media($tablet-min) {
      margin-bottom: 0;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.24rem;
    margin-bottom: .5rem;
    margin-top: 0;

    @include media($mobile-min) {
      font-size: 1.8rem;
      line-height: 2.5rem;
      margin-bottom: .8rem;
    }
  }

  &__specialty {
    font-size: 1.4rem;
    line-height: 1.68rem;
    margin-bottom: 1.8rem;

    @include media($mobile-min) {
      font-size: 1.8rem;
      line-height: 2.5rem;
      margin-bottom: 1.6rem;
    }
  }

  &__education {
    font-size: 1.4rem;
    line-height: 1.68rem;
    margin-bottom: 2.4rem;

    @include media($mobile-min) {
      font-size: 1.6rem;
      line-height: 2.24rem;
      margin-bottom: 3.2rem;
    }

    @include media($tablet-min) {
      font-size: 1.6rem;
      line-height: 2.24rem;
      margin-bottom: 1.3rem;
    }
  }

  &__documents {
    margin-top: auto;
  }

  &__btn-cta {
    @include media($tablet-min) {
      display: none;
    }
  }

  &__popup-trigger {
    width: 100%;

    @include media($tablet-max, '<') {
      display: none;
    }
  }

  &__schedule {
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.68rem;
    margin-top: 2.4rem;

    @include media($mobile-min) {
      font-size: 1.6rem;
      line-height: 2.24rem;
      margin-top: 2.7rem;
    }

    @include media($tablet-min) {
      text-align: left;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 0;
    }

    p {
      &:not(:last-child) {
        margin-bottom: .8rem;
      }
    }
  }
}