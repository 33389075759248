.doctors {
  min-height: 42.4rem;

  &__item {
    &:not(:last-child) {
      margin-bottom: 2.4rem;

      @include media(650) {
        margin-bottom: 0;
      }
    }
  }
}

.doctor-card {
  background-color: #fff;
  padding: 1.6rem 1.6rem 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #111111;

  &__photo {
    width: 25.3rem;
    height: 25.2rem;
    margin-bottom: 2.4rem;
    overflow: hidden;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__name {
    font-size: 1.6rem;
    line-height: 2.24rem;
    text-align: center;
    margin-bottom: .8rem;
    font-weight: 700;

    span {
      display: block;
    }
  }

  &__specialty {
    line-height: 2.24rem;
    margin-bottom: .8rem;
    text-align: center;
  }

  &__link {
    text-align: center;
    font-size: 1.6rem;
    line-height: 2.24rem;
  }
}