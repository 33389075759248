.popup-enter {
  opacity: 0;
}

.popup-enter-active {
  opacity: 1;
  transition: opacity .3s;
}

.popup-exit {
  opacity: 1;
}

.popup-exit-active {
  opacity: 0;
  transition: opacity .3s;
}

.popup-modal {
  width: 800px;
  max-width: 100%;
  padding: 90px;
  background-color: #fff;
  border: none;
  text-align: center;
  transition: opacity .3s ease-in-out;

  &__overlay {
    background-color: rgba(0, 0, 0, 0.65);
  }

  &__close {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 24px;
    top: 24px;
    background-color: #dad7d7;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.34303 7.00955L11.1729 4.17971L9.82301 2.82984L6.99316 5.65968L4.16332 2.82984L2.81346 4.17971L5.6433 7.00955L2.82972 9.82313L4.17959 11.173L6.99316 8.35942L9.80674 11.173L11.1566 9.82313L8.34303 7.00955Z' fill='%23fff'/%3E%3C/svg%3E");
    border: none;
    border-radius: 50%;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.02);
    cursor: pointer;
    transition: all 0.3s;

    &:hover,
    &:focus {
      background-color: $grey-darkest;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
  }

  &__tel {
    display: inline-block;
    font-size: 3rem;
    color: inherit;
    margin: 32px 0;
    font-family: $secondary-font;
  }

  &__subtitle {
    font-weight: bold;
  }

  &__content {
    margin-top: 8px;
    line-height: 1.5;
  }
}

.popup-modal--document {
  padding: 1.6rem;

  @include media ($tablet-max, '<') {
    height: calc( var(--app-height) - 6.4rem);
    max-width: calc(100% - 6.4rem);
    width: 100%;
    overflow-y: scroll;
  }

  @include media ($mobile-max, '<') {
    max-width: calc(100% - 3.2rem);
    max-height: calc(var(--app-height) - 3.2rem);
    overflow-y: scroll;
    height: auto;
  }

  @include media($mobile-min) {
    padding: 5.5rem 13.5rem;
  }

  @include media($tablet-min) {
    padding: 2.4rem;
  }

  .popup-modal__close {
    @include media ($tablet-max, '<') {
      background-color: #1d1d1d;
    }

    @include media ($mobile-max, '<') {
      width: 4rem;
      height: 4rem;
      top: .8rem;
      right: .8rem;
      border: 5px solid #fff; 
    }
  }
  
  .popup-moodal__document {
    display: block;
    width: 100%;
    height: auto;

    @include media($mobile-min) {
      margin: 0 auto;
    }

    @include media($tablet-min) {
      max-width: 35rem;
    }
  }
}

.visually-hidden {
  position: fixed;
  top: 24px;
  right: 24px;
  width: 50px;
  height: 50px;
  transform: scale(0);
}