.contacts-list {
  &__item {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: $text-color-darkest;
    font-size: 1.4rem;

    @include media($mobile-min) {
      font-size: 1.6rem;
    }
  }

  &__icon-box {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.7rem;
  }

  &__icon {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__text {
    &--phone {
      font-family: $secondary-font;
    }
  }

  &--blue {
    .contacts-list__icon {
      fill: $blue-dark;
    }
  }

  &--gold {
    .contacts-list__icon {
      fill: $gold;
    }

    .contacts-list__link {
      color: #fff;
    }
  }
}