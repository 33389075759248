.prices-manager {
  padding: 20px;

  h2 {
    margin-bottom: 30px;
    font-size: 24px;
    color: #333;
  }

  .prices-actions {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  .upload-price-button {
    order: 2;
    padding: 12px 24px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      background-color: #1976D2;
    }

    &::before {
      content: '📄';
      font-size: 20px;
    }
  }

  .add-button {
    order: 1;
    margin-bottom: 0;
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      background-color: #45a049;
    }

    &::before {
      content: '+';
      font-size: 20px;
      font-weight: bold;
    }
  }

  .price-category {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;

    .category-header {
      padding: 15px 20px;
      background: #f5f5f5;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin: 0;
        font-size: 18px;
        color: #333;
      }

      .category-actions {
        display: flex;
        gap: 10px;

        button {
          padding: 8px 16px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.2s;

          &:first-child {
            background: #4CAF50;
            color: white;

            &:hover {
              background: #45a049;
            }
          }

          &:last-child {
            background: #2196F3;
            color: white;

            &:hover {
              background: #1976D2;
            }
          }

          &.delete-button {
            background: #f44336;
            color: white;

            &:hover {
              background: #d32f2f;
            }
          }
        }
      }
    }

    .services-list {
      padding: 20px;

      .service-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #eee;
        gap: 64px;

        &:last-child {
          border-bottom: none;
        }

        .service-info {
          display: flex;
          align-items: center;
          gap: 20px;
          width: 100%;

          .service-code {
            color: #666;
            font-size: 14px;
            min-width: 100px;
          }

          .service-name {
            flex: 1;
            width: 100%;
          }

          .service-price {
            font-weight: bold;
            color: #333;
            min-width: 100px;
            text-align: right;
          }
        }

        .service-actions {
          display: flex;
          gap: 10px;

          button {
            padding: 6px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
            transition: background-color 0.2s;

            &:first-child {
              background: #4CAF50;
              color: white;

              &:hover {
                background: #45a049;
              }
            }

            &:last-child {
              background: #f44336;
              color: white;

              &:hover {
                background: #d32f2f;
              }
            }
          }
        }
      }
    }
  }
}

.category-edit-form,
.service-edit-form {
  padding: 20px;
  min-width: 500px;

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  div {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #444;
  }

  input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;

    &:focus {
      outline: none;
      border-color: #0066cc;
      box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
    }
  }

  .form-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;

      &[type="submit"] {
        background: #4CAF50;
        color: white;

        &:hover {
          background: #45a049;
        }
      }

      &[type="button"] {
        background: #f44336;
        color: white;

        &:hover {
          background: #d32f2f;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .prices-manager {
    padding: 15px;

    .prices-actions {
      flex-direction: column;
      gap: 10px;
    }

    .upload-price-button {
      order: 1;
      width: 100%;
      justify-content: center;
    }

    .add-button {
      order: 2;
      width: 100%;
      justify-content: center;
    }

    .price-category {
      .category-header {
        flex-direction: column;
        gap: 10px;

        .category-actions {
          width: 100%;

          button {
            flex: 1;
          }
        }
      }

      .service-item {
        flex-direction: column;
        gap: 10px;

        .service-info {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          .service-price {
            text-align: left;
          }
        }

        .service-actions {
          width: 100%;

          button {
            flex: 1;
          }
        }
      }
    }
  }

  .category-edit-form,
  .service-edit-form {
    min-width: auto;
    width: 100%;
    padding: 15px;

    .form-actions {
      button {
        flex: 1;
      }
    }
  }
}

.current-price-file {
  margin: 20px 0;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;

  h3 {
    margin-bottom: 15px;
  }

  p {
    margin: 5px 0;
  }

  .file-actions {
    margin-top: 15px;
    display: flex;
    gap: 10px;

    .btn {
      padding: 8px 16px;
    }
  }
}

.upload-section {
  margin: 20px 0;
} 