.admin-doctors {
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      font-size: 24px;
      margin: 0;
    }

    .add-button {
      background-color: #27ae60;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      
      &:hover {
        background-color: #219a52;
      }
    }
  }

  .doctors-table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #eee;
    }

    th {
      background-color: #f8f9fa;
      font-weight: 600;
    }

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
      background: #f5f5f5;
    }

    .edit-button, .delete-button {
      padding: 6px 12px;
      border: none;
      border-radius: 4px;
      margin-right: 8px;
      cursor: pointer;
    }

    .edit-button {
      background-color: #3498db;
      color: white;
      
      &:hover {
        background-color: #2980b9;
      }
    }

    .delete-button {
      background-color: #e74c3c;
      color: white;
      
      &:hover {
        background-color: #c0392b;
      }
    }
  }
}

.doctor-edit-form {
  padding: 30px;
  height: 90vh;
  overflow-y: auto;
  width: 900px;
  max-width: 95vw;

  h2 {
    margin-bottom: 30px;
    text-align: center;
  }

  .form-layout {
    display: flex;
    gap: 30px;
  }

  .photo-column {
    flex: 0 0 300px;
    
    .photo-preview {
      width: 250px;
      height: 250px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      margin-bottom: 15px;
      background: #f5f5f5;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      input[type="file"] {
        position: absolute;
        inset: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .fields-column {
    flex: 1;
  }

  .form-group {
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 8px;
      font-weight: 500;
    }

    input, textarea {
      width: 100%;
      padding: 8px 12px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }

  .diplomas-section {
    border-top: 1px solid #eee;
    padding-top: 30px;
    margin-top: 30px;
    
    .diplomas-preview {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 15px;
      margin-top: 15px;

      .diploma-item {
        position: relative;
        border: 1px solid #ddd;
        border-radius: 4px;
        overflow: hidden;

        img {
          width: 100%;
          height: 150px;
          object-fit: cover;
        }

        .delete-button {
          position: absolute;
          top: 5px;
          right: 5px;
          background: rgba(255, 0, 0, 0.8);
          color: white;
          border: none;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .form-actions {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &.save-button {
        background: #4CAF50;
        color: white;
      }

      &.cancel-button {
        background: #f44336;
        color: white;
      }
    }
  }
}

.react-responsive-modal-modal {
  padding: 0;
  max-height: 90vh;
  width: 900px !important;
  max-width: 95vw !important;
  overflow: hidden;
  border-radius: 8px;
} 